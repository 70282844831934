/* eslint-disable max-statements, max-lines-per-function */
import { useEffect, useMemo, useState } from 'react';
import { ASPECT_RATIOS } from '../constants';
import { AssetEditState, Bounds, Offset, RatioName } from '../types';
import { handleSliderChange } from '../utils';

const CROPPER_CANVAS_GAP = 0.9;
const DEFAULT_SCALE = 1;
const DEFAULT_ROTATION = 0;
const DEFAULT_DIMENSION = 1;

export function useAssetEdit(
    containerRef: AssetEditState['containerRef'],
    draggableAssetRef: AssetEditState['draggableAssetRef'],
    validateAssetIsWithinCropBounds: () => void,
): Omit<AssetEditState, 'containerRef' | 'cropperRef' | 'draggableAssetRef'> {
    const [assetOffset, setAssetOffset] = useState<Offset | null>(null);
    const [assetRotation, setAssetRotation] = useState(DEFAULT_ROTATION);
    // TODO: Confirm the use of asset Position?
    const [assetPosition, setAssetPosition] = useState({ left: DEFAULT_DIMENSION, top: DEFAULT_DIMENSION });
    const [assetScale, setAssetScale] = useState(DEFAULT_SCALE);
    const [cropRatio, setCropRatio] = useState<RatioName>('Original');
    const [canvasWidth, setCanvasWidth] = useState(DEFAULT_DIMENSION);
    const [canvasHeight, setCanvasHeight] = useState(DEFAULT_DIMENSION);
    const [isAssetLoaded, setIsAssetLoaded] = useState(false);
    const [originalCropperRatio, setOriginalCropperRatio] = useState<{
        height: number;
        width: number;
    } | null>(null);

    const handleChangeCropRatio = (ratio: RatioName) => setCropRatio(ratio);
    const handleAssetRotation = handleSliderChange(setAssetRotation);
    const handleAssetScale = handleSliderChange(setAssetScale);
    const handleResetAssetRotation = (value: number) => setAssetRotation(value);
    const handleResetAssetScale = (value: number) => setAssetScale(value);
    const handleFlipRotation = ({ max, min, value }: { value: number; min: number; max: number }) => {
        const newRotation = assetRotation + value;
        if (newRotation >= max) {
            setAssetRotation(max);
        } else if (newRotation <= min) {
            setAssetRotation(min);
        } else {
            setAssetRotation(newRotation);
        }

        validateAssetIsWithinCropBounds();
    };

    // This is called onLoad of the asset in order to size it to the canvas
    const handleAssetLoad = () => {
        const container = containerRef.current;
        const draggableAsset = draggableAssetRef.current;
        if (!container || !draggableAsset) {
            return;
        }

        setAssetOffset({
            x: canvasWidth / 2 - draggableAsset.width / 2,
            y: canvasHeight / 2 - draggableAsset.height / 2,
        });
        // TODO DSL-5169: Remove the need for this - Hack to set the cropper once the asset has been resized
        setTimeout(() => setIsAssetLoaded(true), 10);
    };

    const cropperBounds = useMemo((): Bounds | null => {
        if (!isAssetLoaded) {
            return null;
        }

        const canvasAspectRatio = canvasWidth / canvasHeight;
        const cropAspectRatio = ASPECT_RATIOS[cropRatio];

        let cropperHeight = 0;
        let cropperWidth = 0;

        const assetBounds = draggableAssetRef.current?.getBoundingClientRect();
        // We should initially get the original cropper ratio to be what ever the base asset is
        if (cropRatio === 'Original' && assetBounds) {
            if (originalCropperRatio) {
                cropperWidth = originalCropperRatio.width;
                cropperHeight = originalCropperRatio.height;
            } else if (assetBounds.width !== DEFAULT_DIMENSION) {
                // On the initial load of the ref, the ref loads before the asset does
                // We need to make sure there's a width greater than 0 before adding that as the original
                setOriginalCropperRatio({ height: assetBounds.height, width: assetBounds.width });
                cropperWidth = assetBounds.width;
                cropperHeight = assetBounds.height;
            }
        } else if (cropAspectRatio > canvasAspectRatio) {
            // Match width, adjust height based on aspect ratio
            // The CROPPER_CANVAS_GAP leaves a little gap between the Canvas we have and the cropper
            cropperWidth = canvasWidth * CROPPER_CANVAS_GAP;
            cropperHeight = cropperWidth / cropAspectRatio;
        } else {
            cropperHeight = canvasHeight * CROPPER_CANVAS_GAP;
            cropperWidth = cropperHeight * cropAspectRatio;
        }

        const left = (canvasWidth - cropperWidth) / 2;
        const right = (canvasWidth + cropperWidth) / 2;
        const bottom = (canvasHeight + cropperHeight) / 2;
        const top = (canvasHeight - cropperHeight) / 2;

        return {
            bottom,
            height: cropperHeight,
            left,
            right,
            top,
            width: cropperWidth,
        };
    }, [canvasHeight, cropRatio, draggableAssetRef, canvasWidth, originalCropperRatio, isAssetLoaded]);

    // Listen to see when the DOM is resized
    // We can grab the new container offsetWidth and offsetHeight in and set the new setCanvasWidth and setCanvasHeight
    // Now the cropper should resize to be the correct ratio to the new screen size
    useEffect(() => {
        const updateDimensions = () => {
            const container = containerRef.current;
            if (container) {
                setCanvasWidth(container.offsetWidth);
                setCanvasHeight(container.offsetHeight);
            }
        };
        window.addEventListener('resize', updateDimensions);

        updateDimensions();
        return () => window.removeEventListener('resize', updateDimensions);
    }, [containerRef, draggableAssetRef]);

    useEffect(() => {
        validateAssetIsWithinCropBounds();
    }, [assetRotation, validateAssetIsWithinCropBounds]);

    // In here we update the state that we need to send to the BE
    useEffect(() => {
        const assetRef = draggableAssetRef.current;
        if (!assetRef) {
            return;
        }
        setAssetPosition({
            left: assetRef.offsetLeft,
            top: assetRef.offsetTop,
        });
        validateAssetIsWithinCropBounds();
    }, [assetScale, draggableAssetRef, validateAssetIsWithinCropBounds]);

    return {
        assetOffset,
        assetPosition,
        assetRotation,
        assetScale,
        cropRatio,
        cropperBounds,
        handleAssetLoad,
        handleAssetRotation,
        handleAssetScale,
        handleChangeCropRatio,
        handleFlipRotation,
        handleResetAssetRotation,
        handleResetAssetScale,
        setAssetOffset,
    };
}
