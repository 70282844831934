import { useMutation } from '@apollo/client';
import {
    ImageCropResizeTarget,
    VALIDATE_IMAGE_CROP_RESIZE,
    ValidateImageCropResizeMutation,
    ValidateImageCropResizeMutationVariables,
} from '@deltasierra/frontend/graphql';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useCallback, useState } from 'react';
import { AssetEditState } from '../types';

export type UseAssetEditBackendProps = AlertContextType &
    Pick<
        AssetEditState,
        'assetOffset' | 'assetPosition' | 'assetRotation' | 'assetScale' | 'cropperBounds' | 'cropRatio'
    > & {
        assetId: string;
        onAssetSelected: (asset: { id: string; url: string }) => void;
        t: Translations<'AssetEdit'>;
    };

export function useAssetEditBackend({
    assetId,
    assetOffset,
    assetRotation,
    assetScale,
    cropperBounds,
    onAssetSelected,
    showAlert,
    t,
}: UseAssetEditBackendProps): {
    isLoadingBackend: boolean;
    onClickConfirmCropResize: () => void;
} {
    const [isLoadingBackend, setIsLoadingBackend] = useState(false);
    const [validateImageCropResize] = useMutation<
        ValidateImageCropResizeMutation,
        ValidateImageCropResizeMutationVariables
    >(VALIDATE_IMAGE_CROP_RESIZE);
    const onClickConfirmCropResize = useCallback(async () => {
        try {
            if (!assetOffset || !cropperBounds) {
                throw new Error('Position could not be calculated');
            }
            setIsLoadingBackend(true);
            const { data } = await validateImageCropResize({
                variables: {
                    input: {
                        options: {
                            height: cropperBounds.height,
                            imageOffsetX: assetOffset.x,
                            imageOffsetY: assetOffset.y,
                            imageRotation: assetRotation,
                            imageScale: assetScale,
                            width: cropperBounds.width,
                        },
                        sourceImage: {
                            assetId,
                        },
                        target: ImageCropResizeTarget.ForEmail,
                    },
                },
            });
            if (data?.validateImageCropResize.__typename !== 'ValidateImageCropResizeSuccess') {
                throw new Error(data?.validateImageCropResize.message);
            }
            const asset = data.validateImageCropResize.uploadV2.source;
            onAssetSelected({ id: asset.id, url: asset.signedUrl });
        } catch (error) {
            showAlert('error', t('Image could not be processed'), t('Please try again'));
        } finally {
            setIsLoadingBackend(false);
        }
    }, [
        assetOffset,
        cropperBounds,
        validateImageCropResize,
        assetRotation,
        assetScale,
        assetId,
        onAssetSelected,
        showAlert,
        t,
    ]);

    return {
        isLoadingBackend,
        onClickConfirmCropResize,
    };
}
