import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import { Box } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { AssetEditState, DraggableAssetProps } from './types';

export type AssetEditCanvasProps = DraggableAssetProps &
    Omit<
        AssetEditState,
        | 'handleAssetRotation'
        | 'handleAssetScale'
        | 'handleChangeCropRatio'
        | 'handleFlipRotation'
        | 'handleResetAssetRotation'
        | 'handleResetAssetScale'
        | 'initialAssetScale'
        | 'isCropEnabled'
        | 'setAssetOffset'
    > & {
        asset: AssetFileFragmentFragment;
        t: Translations<'AssetEdit'>;
    };

export const AssetEditCanvas = ({
    asset,
    assetOffset,
    assetRotation,
    assetScale,
    containerRef,
    cropperBounds,
    cropperRef,
    draggableAssetRef,
    handleAssetLoad,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDraggingAsset,
}: AssetEditCanvasProps): JSX.Element => (
    <Box
        ref={containerRef}
        sx={{
            border: 'solid',
            cursor: isDraggingAsset ? 'grabbing' : 'grab',
            flexGrow: 1,
            maxHeight: '800px',
            minHeight: '150px',
            overflow: 'hidden',
            position: 'relative',
        }}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
    >
        {/* Cropper - Shadow to all the cropped out area */}
        {cropperBounds && (
            <Box
                ref={cropperRef}
                sx={{
                    boxShadow: '0 0 0 9999em',
                    boxSizing: 'border-box',
                    color: 'rgba(0, 0, 0, 0.5)',
                    height: `${cropperBounds.height}px`,
                    left: `${cropperBounds.left}px`,
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: `${cropperBounds.top}px`,
                    width: `${cropperBounds.width}px`,
                    zIndex: 10,
                }}
            />
        )}
        {/* Asset */}
        <img
            alt={asset.title || 'image'}
            id="canvas-image"
            ref={draggableAssetRef}
            src={asset.signedUrl}
            style={{
                height: 'auto',
                left: assetOffset ? assetOffset.x : '50%',
                maxWidth: '100%',
                position: 'absolute',
                top: assetOffset ? assetOffset.y : '50%',
                transform: `
                    scale(${assetScale})
                    rotate(${assetRotation}deg)`,
            }}
            onLoad={handleAssetLoad}
        />
    </Box>
);

AssetEditCanvas.displayName = 'AssetEditCanvas';
