import { Box, Button, Slider, SliderProps, Typography } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { Minus, Plus } from '@deltasierra/react/icons';
import { ReactNode } from 'react';

export type AssetEditSliderProps = Required<
    Pick<SliderProps, 'aria-label' | 'defaultValue' | 'max' | 'min' | 'onChange' | 'step' | 'value'>
> & {
    header: ReactNode;
    onClickReset: (value: number) => void;
    t: Translations<'AssetEdit'>;
};

export const AssetEditSlider = ({
    defaultValue,
    header,
    onChange,
    onClickReset,
    step,
    t,
    ...sliderProps
}: AssetEditSliderProps): JSX.Element => (
    <>
        <Box sx={{ height: '2rem' }}>{header}</Box>
        <Slider defaultValue={defaultValue} step={step} onChange={onChange} {...sliderProps} />
        <Box sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'space-between' }}>
            <Minus />
            <Button
                size="small"
                variant="outlined"
                onClick={() => onClickReset(typeof defaultValue === 'number' ? defaultValue : 0)}
            >
                <Typography>{t('Reset')}</Typography>
            </Button>
            <Plus />
        </Box>
    </>
);

AssetEditSlider.displayName = 'AssetEditSlider';
