import { useTranslations } from '@deltasierra/translations/react';
import { AlertContextType } from '@deltasierra/react/components/core';
import { AssetEdit, AssetEditProps } from './AssetEdit';
import { useAssetEdit, useAssetRefs, useDragAsset, useAssetEditBackend } from './hooks';
import { useAssetBoundsValidation } from './hooks/useAssetBoundsValidation';

export type AssetEditContainerProps = AlertContextType & Pick<AssetEditProps, 'asset'> & {
    onClickCancel: () => void;
} & { onAssetSelected: (asset: { id: string; url: string }) => void };

export const AssetEditContainer = ({ asset, onAssetSelected, onClickCancel, showAlert }: AssetEditContainerProps): JSX.Element => {
    const { containerRef, cropperRef, draggableAssetRef } = useAssetRefs();

    const { isCropEnabled, validateAssetIsWithinCropBounds } = useAssetBoundsValidation({
        cropperRef,
        draggableAssetRef,
    });
    const {
        assetOffset,
        assetPosition,
        assetRotation,
        assetScale,
        cropperBounds,
        cropRatio,
        handleAssetLoad,
        handleAssetRotation,
        handleAssetScale,
        handleChangeCropRatio,
        handleFlipRotation,
        handleResetAssetRotation,
        handleResetAssetScale,
        setAssetOffset,
    } = useAssetEdit(containerRef, draggableAssetRef, validateAssetIsWithinCropBounds);

    const { handleMouseDown, handleMouseMove, handleMouseUp, isDraggingAsset } = useDragAsset({
        draggableAssetRef,
        setAssetOffset,
        validateAssetIsWithinCropBounds,
    });
    const t = useTranslations('AssetEdit');
    const { isLoadingBackend, onClickConfirmCropResize } = useAssetEditBackend({
        assetId: asset.id,
        assetOffset,
        assetPosition,
        assetRotation,
        assetScale,
        cropRatio,
        cropperBounds,
        onAssetSelected,
        showAlert,
        t,
    });

    return (
        <AssetEdit
            asset={asset}
            assetOffset={assetOffset}
            assetPosition={assetPosition}
            assetRotation={assetRotation}
            assetScale={assetScale}
            containerRef={containerRef}
            cropRatio={cropRatio}
            cropperBounds={cropperBounds}
            cropperRef={cropperRef}
            draggableAssetRef={draggableAssetRef}
            handleAssetLoad={handleAssetLoad}
            handleAssetRotation={handleAssetRotation}
            handleAssetScale={handleAssetScale}
            handleChangeCropRatio={handleChangeCropRatio}
            handleFlipRotation={handleFlipRotation}
            handleMouseDown={handleMouseDown}
            handleMouseMove={handleMouseMove}
            handleMouseUp={handleMouseUp}
            handleResetAssetRotation={handleResetAssetRotation}
            handleResetAssetScale={handleResetAssetScale}
            isCropEnabled={isCropEnabled}
            isDraggingAsset={isDraggingAsset}
            isLoadingBackend={isLoadingBackend}
            t={t}
            onClickCancel={onClickCancel}
            onClickConfirmCropResize={onClickConfirmCropResize}
        />
    );
};

AssetEditContainer.displayName = 'AssetEditContainer';
